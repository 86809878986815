import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthGuard } from '../../modules/auth/guards/auth.guard';

@Injectable({
  providedIn: 'root',
})
export class TaskAuthGuard extends AuthGuard {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return super.canActivate(route, state)
                  .pipe(
                    map(canActivate => {
                      if(canActivate) {
                        if (this.auth.isSuperAdmin) {
                          this.router.navigateByUrl('/user')
                          return false;
                        }
                      }
                      return canActivate;
                    })
                  );
    
  }
}
